import { storeToRefs } from 'pinia'
import { useSoulCardMintStore } from 'src/stores/soulCardMint'
import { computed } from 'vue'
export function useTheme(props) {
  const { phaseComputed } = storeToRefs(useSoulCardMintStore())
  const classComputed = computed(() => {
    const phase = props.phase
    const currentPhase = phaseComputed.value

    if (phase > currentPhase) {
      return 'not_start'
    } else if (phase === currentPhase) {
      return 'ing'
    } else {
      return 'end'
    }
  })
  return {
    classComputed,
  }
}
