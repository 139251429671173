<script setup>
import SvgIcon from 'src/components/SvgIcon'
import RedPopover from 'src/components/RedPopover'
import { ref } from 'vue'

const activeTab = ref(0)
</script>

<template>
  <div class="flex fz-5 border-0 border-b border-solid border-#6B7B87">
    <div
      :class="`${activeTab === 0 ? 'active' : 'static'}`"
      class="item hover w-163px"
    >
      GENESIS
      <SvgIcon
        v-if="activeTab === 0"
        name="PFP-WAVE"
        width="256px"
        height="3px"
        class="absolute bottom-0 text-#6b7b87"
      ></SvgIcon>
    </div>
    <RedPopover>
      <template #trigger>
        <div
          :class="`${activeTab === 1 ? 'active' : 'static'}`"
          class="item transition-color transition-duration-250 transition-ease-in-out w-198px"
        >
          ETHEREAL
          <SvgIcon
            v-if="activeTab === 1"
            name="PFP-WAVE"
            width="256px"
            height="3px"
            class="absolute bottom-0 text-#6b7b87"
          ></SvgIcon>
        </div>
      </template>
      <div>Coming Soon ......</div>
    </RedPopover>
    <RedPopover>
      <template #trigger>
        <div
          :class="`${activeTab === 2 ? 'active' : 'static'}`"
          class="item transition-color transition-duration-250 transition-ease-in-out w-198px"
        >
          SOULCARD
          <SvgIcon
            v-if="activeTab === 2"
            name="PFP-WAVE"
            width="256px"
            height="3px"
            class="absolute bottom-0 text-#6b7b87"
          ></SvgIcon>
        </div>
      </template>
      <div>Coming Soon ......</div>
    </RedPopover>
  </div>
</template>

<style lang="scss" scoped>
.hover {
  @apply hover:text-#cdd8e0;
}

.item {
  @extend .flex-center;
  @extend .mouse_pointer;
  @apply relative h-41px border  border-b-0 border-solid rounded-tr rounded-bl-5px;

  &.active {
    @apply bg-#323c44 text-#cdd8e0 border-#6b7b87 transition-all transition-ease-in-out transition-duration-200;
  }

  &.static {
    @apply bg-#323C44/30 text-#444950 border-#6b7b8761;
  }
}
</style>
