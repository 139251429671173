import { useMessage } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useAsyncState } from '@vueuse/core'
import { useLoginStore } from 'src/stores/user'
import { fetchSaleListInfo, fetchCreateSoulCard } from 'src/api'
import { errorCodeToMessage } from 'src/helpers/utils'
import eggImg from 'src/asset/PFP/egg.webp'
import {
  getBlockchainErrorCode,
  getBlockchainNeedErrorMessage,
} from 'src/sdk/blockchain'
import { loginOut, fetchAuth } from 'src/sdk/immutable'
import { soulCard } from 'src/helpers/blockchain'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { computed, ref } from 'vue'
import { Web3Provider } from '@ethersproject/providers'
import { checkoutSDK, passportProvider } from 'src/sdk/immutable'

export function useMint() {
  const immutableInfoStore = useImmutableInfoStore()
  const { open: login } = useLoginStore()
  const { hasLogin } = storeToRefs(useLoginStore())
  const message = useMessage()
  const currentPhaseWLComputed = ref(10)
  const { immutableCurrentBindAccount } = storeToRefs(immutableInfoStore)
  const quantityRef = ref(1)
  const mintRef = ref(null)
  const quantityMaxComputed = computed(() =>
    currentPhaseWLComputed.value > 5 ? 5 : currentPhaseWLComputed.value,
  )
  const successShowRef = ref(false)
  const errorShowRef = ref(false)
  const checkoutModalRef = ref(false)
  const cardsShowRef = ref(false)
  const mintConfirmShowRef = ref(false)
  const mintListRef = ref([])
  const widgetTypeRef = ref('')
  const orderDataRef = ref({})
  const walletAddress = ref('')
  const productsListRef = ref([])

  const { execute: executeProducts } = useAsyncState(
    async () => {
      const data = await fetchSaleListInfo({
        web_type: 'soulCard',
      })
      const extraInfo = {
        picture: eggImg,
      }
      orderDataRef.value = {
        ...data,
        ...extraInfo,

        contract_address: '0x095bDf251bEE0C9462f0c2B1FF5ddd78bB401480',
        id: 2,
        pricing: [
          {
            amount: 0,
            currency: 'USDC',
            id: 5,
            product_id: 2,
            currency_type: 'crypto',
            currency_address: null,
          },
          {
            amount: 0,
            currency: 'ETH',
            id: 6,
            product_id: 2,
            currency_type: 'crypto',
            currency_address: null,
          },
          {
            amount: 0,
            currency: 'USD',
            id: 7,
            product_id: 2,
            currency_type: 'fiat',
            currency_address: null,
          },
        ],
      }
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
        errorCodeToMessage(error)
      },
    },
  )

  function getWalletAddress(wallet) {
    console.log('wallet', wallet)
    // walletAddress.value = wallet
  }

  async function handleConfirm() {
    mintConfirmShowRef.value = false
    const { accounts } = await fetchAuth()
    if (accounts[0].toLowerCase() === immutableCurrentBindAccount.value) {
      widgetTypeRef.value = 'sale'
      checkoutModalRef.value = true
      walletAddress.value = accounts[0].toLowerCase()
    } else {
      mintConfirmShowRef.value = true
      loginOut()
    }
  }

  async function handleMint() {
    if (!hasLogin.value) {
      login()
      return
    }
    const provider = new Web3Provider(passportProvider)
    if (provider) {
      const connectResult = await checkoutSDK.connect({
        provider,
        requestWalletPermissions: true,
      })
      const address = await connectResult.provider.getSigner().getAddress()
      if (address.toLowerCase() !== immutableCurrentBindAccount.value) {
        loginOut()
        mintConfirmShowRef.value = true
        return
      } else {
        walletAddress.value = address.toLowerCase()
      }
    }
    orderDataRef.value.qty = quantityRef.value
    widgetTypeRef.value = 'sale'
    checkoutModalRef.value = true
  }

  async function handleMintSuccess(list) {
    mintListRef.value = []
    successShowRef.value = true
    // mintListRef.value = list
    quantityRef.value = 0
    const listCalls = list.map(tokenId => {
      return fetchCreateSoulCard({
        tokenId,
        walletaddr: walletAddress.value,
      })
    })
    await Promise.all(listCalls)
    const soulCardList = await soulCard.tokenIdsToNFTList(list)
    mintListRef.value = soulCardList
    console.log('handleMintSuccess', list, soulCardList)
  }
  function handleMintError(list, error) {
    const code = getBlockchainErrorCode(error)
    if (code === -1) {
      errorShowRef.value = true
      mintListRef.value = list
    } else {
      message.error(getBlockchainNeedErrorMessage(error))
    }
    console.log('handleMintError', list, error, code)
  }

  executeProducts()

  return {
    quantityRef,
    checkoutModalRef,
    successShowRef,
    errorShowRef,
    cardsShowRef,
    mintListRef,
    mintRef,
    productsListRef,
    widgetTypeRef,
    orderDataRef,
    quantityMaxComputed,
    mintConfirmShowRef,
    handleMintSuccess,
    handleMintError,
    handleMint,
    handleConfirm,
    getWalletAddress,
  }
}
