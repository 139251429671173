<script setup>
import { useTime } from '../hooks/time'
import Countdown from './Countdown.vue'
import { useMediaStore } from 'src/stores/media'
import { storeToRefs } from 'pinia'
const {
  serverTimeComputed,
  startTimeComputed,
  endTimeComputed,
  handleTimeEnd,
} = useTime()
const { min2XL } = storeToRefs(useMediaStore())
</script>
<template>
  <div
    class="box 2xl:pl-6 pl-4 md:pt-2.5"
    :class="`${min2XL ? 'box_2xl' : 'box_md'}`"
  >
    <div class="color-#CDD8E0 2xl:mb-1.5 md:mb-1 fz-3">
      THE OFFICIAL MINTING STARTS IN:
    </div>
    <Countdown
      :start-time="startTimeComputed"
      :end-time="endTimeComputed"
      :current-time="serverTimeComputed"
      dayColor="#8397A0"
      @end="handleTimeEnd"
    ></Countdown>
  </div>
</template>
<style scoped lang="scss">
.box {
  @include bg100('src/asset/PFP/mint/stage1_bg.webp');
}

.box_2xl {
  @apply w-509px h-133px;
}

.box_md {
  @apply w-433px h-113px;
}
</style>
