<script setup>
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from 'vue'
import { gsap } from 'gsap'
import { useElementBounding, useDebounceFn } from '@vueuse/core'
import { formatDecimal } from '@BOGX/utils'
const props = defineProps({
  total: {
    type: Number,
    default: 0,
  },
  value: {
    type: Number,
    default: 0,
  },
})
const progressComputed = computed(() => {
  const number = 0
  // return 100
  return formatDecimal(Math.min(isNaN(number) ? 0 : number, 1) * 100)
})
const completeComputed = computed(() => progressComputed.value === 100)

const progressRef = ref(null)
const completedRef = ref(null)
let completedTl

function initAnimation() {
  nextTick(() => {
    const { width: progressRefWidth } = useElementBounding(progressRef)
    const currentWidth = progressRefWidth.value - 53
    if (completedTl) {
      const progress = completedTl.progress()
      completedTl.kill()
      completedTl = gsap
        .to(completedRef.value, {
          x: currentWidth,
          duration: 1.5 * (1 - progress),
          onComplete() {
            gsap.set(completedRef.value, { x: 0 })
            initAnimation()
          },
        })
        .progress(progress)
    } else {
      gsap.set(completedRef.value, { x: 0, left: 0 })
      completedTl = gsap.to(completedRef.value, {
        ease: 'none',
        opacity: 1,
        x: currentWidth,
        delay: 1,
        duration: 1.5,
        onComplete() {
          completedTl?.restart()
        },
      })
    }
  })
}

watch(
  completeComputed,
  val => {
    console.log('completeComputed', val, completeComputed.value)
    if (val) {
      initAnimation()
    }
  },
  {
    immediate: true,
  },
)

onMounted(() => {
  const debouncedFn = useDebounceFn(() => {
    if (completeComputed.value) {
      initAnimation()
    }
  }, 100)
  window.addEventListener('resize', debouncedFn)
})

onUnmounted(() => {
  window.removeEventListener('resize', initAnimation)
  completedTl?.kill()
})
</script>
<template>
  <div>
    <div ref="progressRef" class="flex-sb mb-1.5 px-1">
      <span class="color-#8397A0">Total Minted</span>
      <div>
        <span :class="{ 'color-#8A2CEA': completeComputed }">{{ 0 }}/</span>
        <span class="color-#8A2CEA">{{ 2000 }}</span>
      </div>
    </div>
    <div
      class="relative flex-center border-#8A2CEA border border-solid w-full h-15px skew-x-30"
    >
      <div
        class="bg-#8A2CEA h-full absolute left-0 top-0"
        :style="{ width: progressComputed + '%' }"
      ></div>
      <span class="fz-1 -skew-x-30">{{ progressComputed }}%</span>
      <img
        ref="completedRef"
        :class="{ 'opacity-50': completeComputed }"
        class="absolute left-0 top-0 opacity-0 h-full -skew-x-30"
        src="/src/asset/PFP/mint/completed.webp"
        alt=""
      />
    </div>
  </div>
</template>
