import { onUnmounted, ref, watch } from 'vue'
import { pfpNFT } from 'src/helpers/blockchain'
import { useSoulCardMintStore } from 'src/stores/soulCardMint'
import { storeToRefs } from 'pinia'
import { useMessage } from 'naive-ui'
import { watchNetwork } from '@wagmi/core'
import { useAsyncState } from '@vueuse/core'
import hoverMintImg from 'src/asset/PFP/mint/Mint_Button_hover.webp'
export function useMint(props, emit) {
  const message = useMessage()
  const pfpMintStore = useSoulCardMintStore()
  const { getSoulCardMintInfo } = pfpMintStore
  const btnDomRef = ref(null)
  const {
    wholeActivityStatusComputed,
    mintCompleteComputed,
    currentPhaseWLComputed,
  } = storeToRefs(pfpMintStore)

  const mintNotWhiteListShowRef = ref(false)

  const watchNetworkStop = watchNetwork(() => {
    mintNotWhiteListShowRef.value = false
  })

  let watchImgStop
  watchImgStop = watch(
    wholeActivityStatusComputed,
    value => {
      if (value === 2) {
        const img = new Image()
        img.src = hoverMintImg
        if (watchImgStop) {
          watchImgStop?.()
        }
      }
    },
    {
      immediate: true,
    },
  )

  async function mintVerify() {
    if (wholeActivityStatusComputed.value === 1) {
      // mint活动未开始
      message.info('Mint event has not started.')
      return false
    } else if (wholeActivityStatusComputed.value === 3) {
      // mint活动已结束
      message.info('Mint event has ended.')
      return false
    } else if (!currentPhaseWLComputed.value) {
      // 没有白名单
      handleMintNotWhiteListShow()
      return false
    } else if (mintCompleteComputed.value) {
      // mint总量达到上限
      message.info('Opps. all items have been minted')
      return false
    } else if (!props.quantity) {
      // mint次为0
      message.info('Mint quantity cannot be 0')
      return false
    }
    return true
  }
  const { execute: handleMint, isLoading: loading } = useAsyncState(
    async quantity => {
      if (await mintVerify()) {
        message.info(
          'In the process of Minting, please wait patiently and refrain from closing or refreshing the page.',
        )
        try {
          const list = await pfpNFT.mint({}, quantity)
          emit('success', list)
          return list
        } catch (error) {
          console.error('handleMint', error)
          emit('error', new Array(quantity), error)
        } finally {
          getSoulCardMintInfo()
        }
      }
    },
    null,
    {
      immediate: false,
    },
  )
  function handleMintNotWhiteListShow() {
    mintNotWhiteListShowRef.value = true
  }
  function handleMintNotWhiteListShow() {
    mintNotWhiteListShowRef.value = true
  }
  onUnmounted(() => {
    watchNetworkStop()
  })
  return {
    loading,
    handleMint,
    mintNotWhiteListShowRef,
    btnDomRef,
  }
}
