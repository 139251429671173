<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useSoulCardMintStore } from 'src/stores/soulCardMint'
import { NPopover } from 'naive-ui'
import { hasPhone } from 'src/stores/media'
const props = defineProps({
  phase: Number,
})
const { mintInfo } = storeToRefs(useSoulCardMintStore())
const wlComputed = computed(() => {
  if (props.phase === 1) {
    return mintInfo.value?.gtdAmount
  } else {
    return mintInfo.value?.fcfsAmount
  }
})

const statusComputed = computed(() => {
  const wl = wlComputed.value
  const paddingX = !hasPhone ? 26 : 8
  return {
    paddingX,
    themeOverrides: {
      borderRadius: '4px',
      padding: !hasPhone ? `4px ${paddingX}px` : `0px ${paddingX}px 2px`,
      color: wl ? '#0a2a19' : '#280d0e',
      textColor: wl ? '#14994F' : '#d21d1d',
      space: '8px',
    },
  }
})
</script>
<template>
  <NPopover
    :theme-overrides="statusComputed.themeOverrides"
    class="RedPopover"
    content-class="RedPopover-content-class"
    placement="left"
    :show-arrow="false"
  >
    <template #trigger>
      <slot></slot>
    </template>
    <div class="relative">
      <div v-if="wlComputed">
        <span class="text-#6b7d6d">You're on the whitelist with</span>
        <span class="text-#fff fz-3 inline-block ml-1.5 mr-1.5">{{
          wlComputed
        }}</span>
        <span class="text-#41b962"
          >{{ phase === 1 ? 'GTD' : 'FCFS' }} spots</span
        >
      </div>
      <div v-else class="text-#FF8585">You're not on the whitelist</div>
      <div
        class="absolute transform-rotate-270 top-[calc(50%-3px)]"
        :class="`triangle_${wlComputed ? 'green' : 'red'}`"
        :style="{ right: `-${statusComputed.paddingX + 5 + 6}px` }"
      ></div>
    </div>
  </NPopover>
</template>

<style scoped lang="scss">
.triangle_green {
  @include triangle($color: #14994f);
}

.triangle_red {
  @include triangle($color: #d21d1d);
}

.RedPopover {
  // .arrow {
  // }

  // . {
  //   @apply border border-solid border-#45C266;
  // }
}
</style>
