<script setup>
import { computed, ref } from 'vue'
import Modal from 'src/components/Modal'
import { NSpin } from 'naive-ui'
import { useLoginStore } from 'src/stores/user'
import { hasPhone } from 'src/stores/media'
import { useRouter } from 'vue-router'
import { desensitizationEmail } from '@BOGX/utils'
import { useImmutableInfoStore } from 'src/stores/immutable'
// import DownloadBOGX from 'src/components/DownloadBOGX'
import ExternalLinks from 'src/components/ExternalLinks'
import { storeToRefs } from 'pinia'
import { useMusicStore } from 'src/stores/music'
import AccountCodeModal from './AccountCodeModal'
import WalletItem from './WalletItem'
import OKXWalletItem from './Wallet/OKXWalletItem'
import ProfileModal from './Profile/ProfileModal'
import UserAccName from './UserAccName'
import SoulsFull from './SoulsFull'
import TrailerSubFull from './TrailerSubFull'
import ImmutableItem from './ImmutableItem'
import { INVENTORY, GAME_BANEFITS_NAME, COLLECTION_NAME } from 'src/constant'
const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['update:show'])
const { buttonClickAudioPlay } = useMusicStore()
const router = useRouter()
const loginStore = useLoginStore()
const { logOut, open: login } = loginStore
const { hasLogin } = storeToRefs(loginStore)

const immutableStore = useImmutableInfoStore()
const { immutableInfo } = storeToRefs(immutableStore)

const accountCodeShowRef = ref(false)
const profileShowRef = ref(false)
// const downloadShowRef = ref(false)
const soulsSubShowRef = ref(false)
const trailerSubShowRef = ref(false)
const allLoadingRef = ref(false)
const linksShowRef = ref(false)
const immutableItemRef = ref(null)
const phoneLoginRef = computed(() => hasLogin.value && hasPhone)
const logoMBRef = computed(() => {
  if (hasPhone) {
    if (hasLogin.value) {
      return 3
    } else {
      return 4
    }
  } else {
    return 5
  }
})
const loadingRef = computed(() => {
  return allLoadingRef.value || (immutableItemRef.value?.loading ?? false)
})
function handleOpenSouls() {
  buttonClickAudioPlay()
  soulsSubShowRef.value = true
}
function handleOpenTrailer() {
  buttonClickAudioPlay()
  trailerSubShowRef.value = true
}
function handleClose(value = false) {
  emit('update:show', value)
}

async function handleLogOut() {
  try {
    allLoadingRef.value = true
    await logOut()
    if (!hasPhone) {
      handleClose()
    }
  } finally {
    allLoadingRef.value = false
  }
}
function handleAccountCodeShow() {
  accountCodeShowRef.value = true
}
function handleProfileShow() {
  profileShowRef.value = true
}
function handleInventory() {
  buttonClickAudioPlay()
  router.push({
    name: INVENTORY,
  })
}

function handleBenefits() {
  buttonClickAudioPlay()
  router.push({
    name: GAME_BANEFITS_NAME,
  })
}

function handleOpenLinks() {
  linksShowRef.value = true
}
function goToMint() {
  buttonClickAudioPlay()
  window.open(import.meta.env.VITE_PFP_BASE_PATH + '/mint')
}
function handleMyCollection() {
  buttonClickAudioPlay()
  router.push({
    name: COLLECTION_NAME,
  })
}
const softPledgeShow = import.meta.env.VITE_SOFT_PLEDGE_SHOW
const okxShow = import.meta.env.VITE_OKX_SHOW
const gameBanefitsShow = import.meta.env.VITE_GAME_BANEFITS_SHOW
</script>

<template>
  <Modal :show="props.show" transform-origin="top" @update:show="handleClose">
    <div class="modal_box">
      <NSpin :show="loadingRef" type="small">
        <div class="pb-2.5 md:pb-6">
          <span class="modal_triangle_top"></span>
          <div class="item_title_bar" :class="`mb-${logoMBRef}`">
            <img
              src="/src/asset/labs_logo.webp"
              alt=""
              class="w-[129px] md:w-[158px]"
            />
          </div>
          <ul class="item-ul px-1.5 md:px-2">
            <template v-if="hasLogin">
              <li class="item-li">
                <UserAccName></UserAccName>
              </li>
              <li class="flex-sb item-li">
                <WalletItem></WalletItem>
              </li>
              <li class="flex-sb item-li" v-if="okxShow">
                <OKXWalletItem></OKXWalletItem>
              </li>
              <li class="flex-sb item-li">
                <ImmutableItem ref="immutableItemRef"></ImmutableItem>
              </li>
              <li
                v-if="immutableInfo?.userProfile?.email"
                class="flex-sb item-li flex items-center"
              >
                <img
                  src="/src/asset/mail.webp"
                  alt=""
                  class="w-29px align-middle md:h-[20px]"
                />
                <span class="flex-1 text-center gray2-color">{{
                  desensitizationEmail(immutableInfo?.userProfile?.email)
                }}</span>
              </li>

              <li class="item2-li" @click="handleProfileShow">Profile</li>
              <li
                v-if="softPledgeShow"
                class="item2-li color-#F1DAB3! jin-bg"
                @click="handleInventory"
              >
                Inventory
              </li>
              <li
                v-if="gameBanefitsShow"
                class="item2-li color-#F1DAB3! jin-bg"
                @click="handleBenefits"
              >
                Token & Benefits
              </li>
              <li v-else class="item2-li" @click="handleMyCollection">
                My Collection
              </li>
              <li class="item2-li" @click="handleAccountCodeShow">
                Redeem Code
              </li>
            </template>
            <!-- <DownloadBOGX
              v-model:show="downloadShowRef"
              :placement="!hasPhone ? 'right' : 'top'"
              :show-arrow="false"
            >
              <li class="item2-li hover-triangle-li relative">
                Download BOGX
                <span
                  v-if="!hasPhone"
                  class="triangle absolute"
                  :style="`transform: translate(105%, -50%) rotate(${
                    downloadShowRef ? -270 : -90
                  }deg)`"
                ></span>
              </li>
            </DownloadBOGX> -->
            <template v-if="hasPhone">
              <li class="item2-li" @click="handleOpenTrailer">Trailer</li>
              <li class="item2-li" @click="handleOpenSouls">Souls</li>
              <li class="item2-li" @click="handleOpenLinks">Links</li>
              <li class="item2-li border-#FFA88A! bg-#EA852C!">
                <div class="block w-full text-white" @click="goToMint">
                  MINT
                </div>
              </li>
              <!-- <li
                v-else
                class="item2-li relative border-#E48282 bg-#8D4040!"
                @click="buttonClickAudioPlay"
              >
                <span class="text-white">MINT</span>
                <span
                  class="fz-2 absolute right-[25px] top-50% translate-y-[-50%] text-#F4DD80"
                  >Coming Soon ...</span
                >
              </li> -->

              <!-- <li
                class="item2-li relative border-#E48282 bg-#8D4040!"
                @click="buttonClickAudioPlay"
                :class="`mb-${phoneLoginRef ? 3 : 6}!`"
              >
                <span class="text-white">Marketplace</span>
                <span
                  class="fz-2 absolute right-[25px] top-50% translate-y-[-50%] text-#F4DD80"
                  >Coming Soon ...</span
                >
              </li> -->
            </template>
            <li
              class="item-func-li flex-center"
              @click="hasLogin ? handleLogOut() : login()"
            >
              {{ hasLogin ? 'Log out' : 'Log in' }}
            </li>
          </ul>
        </div>
      </NSpin>
    </div>
  </Modal>
  <AccountCodeModal v-model:show="accountCodeShowRef"></AccountCodeModal>
  <ProfileModal v-model:show="profileShowRef"></ProfileModal>
  <SoulsFull v-if="hasPhone" v-model:show="soulsSubShowRef"></SoulsFull>
  <TrailerSubFull
    v-if="hasPhone"
    v-model:show="trailerSubShowRef"
  ></TrailerSubFull>
  <Modal v-if="hasPhone" v-model:show="linksShowRef">
    <div class="p-2 bg-#343A40 rounded-2">
      <ExternalLinks></ExternalLinks>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
@import './ul';

.triangle {
  top: 50%;
  right: 0;
  z-index: 2011;

  @include triangle;
}

.jin-bg {
  background: linear-gradient(
    90deg,
    rgb(140 99 52 / 100%) 0%,
    rgb(171 123 66 / 100%) 27.37%,
    rgb(171 123 66 / 100%) 72.24%,
    rgb(140 99 52 / 100%) 100%
  );
  border-color: #e4a245;

  &:hover {
    background: linear-gradient(#b48958, #b48958);
  }
}
</style>
