import { useSoulCardMintStore } from 'src/stores/soulCardMint'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export function usePhaseStatus(immutablePhaseValue) {
  const { phaseComputed, mintCompleteComputed } = storeToRefs(
    useSoulCardMintStore(),
  )
  const phaseStatusComputed = computed(() => {
    if (immutablePhaseValue.value > phaseComputed.value) {
      return 1
    } else if (
      immutablePhaseValue.value === phaseComputed.value &&
      !mintCompleteComputed.value
    ) {
      return 2
    } else {
      return 3
    }
  })

  const ingComputed = computed(() => phaseStatusComputed.value === 2)
  return {
    phaseStatusComputed,
    ingComputed,
  }
}
