<script setup>
import { NSpin, useMessage } from 'naive-ui'
import CheckinyWebp from 'src/asset/softPledge/checkin.webp'
import CheckedyWebp from 'src/asset/softPledge/checked.webp'
import CheckinTooltips from './CheckinTooltips.vue'
import PointModal from './PointModal.vue'
import BindImmutableModal from './BindImmutableModal.vue'
import { storeToRefs } from 'pinia'
import { useSoftPledgeInfoStore } from 'src/stores/softPledge'
import { ref, computed } from 'vue'
import { useLoginStore } from 'src/stores/user'
import { useImmutable } from 'src/hooks/useImmutable'
const { playingInfo, signInLoading } = storeToRefs(useSoftPledgeInfoStore())
const { executeSignIn, executeReawardList } = useSoftPledgeInfoStore()

const { open: login } = useLoginStore()
const { hasLogin } = storeToRefs(useLoginStore())
const message = useMessage()

const { userImmutableWallet, bindImmutable } = useImmutable()
const { isLoading: loadingBindRef, execute: executeBindImmutable } =
  bindImmutable

const isChecked = computed(() => playingInfo.value?.check)
const showTooltips = ref(false)
const point = ref(0)

const showBindImmutableModalRef = ref(false)

const showPointModalRef = ref(false)
async function handleCheckin() {
  showTooltips.value = false
  if (!hasLogin.value) {
    await login()
    executeReawardList()
    return
  }
  if (!userImmutableWallet.value) {
    message.warning('Please bind your immutable account first.')
    showBindImmutableModalRef.value = true
    return
  }
  point.value = await executeSignIn()
  showPointModalRef.value = true
}

async function handleBind() {
  executeBindImmutable()
}
</script>
<template>
  <div
    @mouseenter="showTooltips = true"
    @mouseleave="showTooltips = false"
    @click="handleCheckin"
    class="mouse_pointer relative flex"
  >
    <img
      class="w-58px h-52px mr-18px transition-all"
      :src="isChecked ? CheckedyWebp : CheckinyWebp"
      alt=""
    />
    <div class="bg-box py-10px px-15px w-314px h-63px fz-5 text-#fff">
      <div>CHECK IN</div>
      <div class="h-1px w-full bg-#50585D mt-1"></div>
      <div
        :class="isChecked ? 'opacity-0' : 'opacity-100'"
        class="absolute -top-7px -left-7px bg-#F92C2C w-15px h-15px border-1.5px border-solid border-#FFFFFF rotate-45 transition-all"
      ></div>
      <CheckinTooltips
        v-if="showTooltips"
        class="absolute -left-80px top-15px"
      ></CheckinTooltips>
    </div>
    <PointModal v-model:show="showPointModalRef" :point="point"></PointModal>
    <div v-if="signInLoading" class="absolute h-full w-full">
      <NSpin :size="24" class="absolute-center"></NSpin>
    </div>
    <BindImmutableModal
      :wallet="userImmutableWallet"
      :isLoading="loadingBindRef"
      v-model:show="showBindImmutableModalRef"
      @confirm="handleBind"
    ></BindImmutableModal>
  </div>
</template>

<style lang="scss" scoped>
.bg-box {
  position: relative;
  background: url('/src/asset/softPledge/right_bg.webp') no-repeat center / 100%
    100%;
}
</style>
