<script setup>
import SphereLogo from 'src/asset/soulCardMint/Sphere.webp'
import TokentroveLogo from 'src/asset/soulCardMint/Tokentrove.webp'
import OkxLogo from 'src/asset/soulCardMint/Okx.webp'

const linkList = [
  {
    name: 'Sphere',
    logo: SphereLogo,
    link: '',
    width: 75,
  },
  {
    name: 'Tokentrove',
    logo: TokentroveLogo,
    link: '',
    width: 90.5,
  },
  {
    name: 'Okx',
    logo: OkxLogo,
    link: '',
    width: 141,
  },
]
</script>
<template>
  <ul class="flex">
    <template v-for="item in linkList" :key="item.name">
      <li class="mouse_pointer mr-2 last:mr-0">
        <a class="flex" :href="item.link" target="_blank">
          <img :style="{ width: `${item.width}px` }" :src="item.logo" alt="" />
        </a>
      </li>
    </template>
  </ul>
</template>
