import { ref, watch, computed, watchEffect } from 'vue'
import { useMessage } from 'naive-ui'
import { useAsyncState } from '@vueuse/core'
import { shuffle, throttle } from 'lodash-es'
import {
  fetchOkxMarketplaceNftList,
  fetchOkxMarketplaceNftDetail,
  fetchOkxMarketplaceNftListings,
} from 'src/api'

export function useMarketPlace() {
  const okxList = ref([])
  const cursor = ref('')
  const listCursor = ref('')
  let currentList
  const { execute: executeList } = useAsyncState(
    async () => {
      const params = {
        cursor: cursor.value ? cursor.value : null,
        limit: 50,
      }
      // 过滤掉值为 null 的参数
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value !== null),
      )

      const { data } = await fetchOkxMarketplaceNftList(filteredParams)
      currentList = data.data

      okxList.value.push(...data.data)
      cursor.value = data.cursor
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )

  function nextPage() {
    if (currentList.length >= 50) {
      executeList()
    }
  }

  function handleListScroll(event) {
    if (
      event.srcElement.scrollTop + event.srcElement.clientHeight >=
      event.srcElement.scrollHeight - 100
    ) {
      nextPage()
    }
  }
  const throttleListScroll = throttle(handleListScroll, 100)

  const { execute: executeInfo } = useAsyncState(
    async () => {
      const params = {
        cursor: listCursor.value ? listCursor.value : null,
        limit: 50,
      }
      // 过滤掉值为 null 的参数
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value !== null),
      )

      const { data } = await fetchOkxMarketplaceNftListings(filteredParams)

      console.log(data)
      listCursor.value = data.listCursor
      // if (data.data.length >= 50) {
      //   executeInfo()
      // }
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )
  executeList()
  executeInfo()
  return {
    okxList,
    throttleListScroll,
  }
}
