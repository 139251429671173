<script setup>
import Market from 'src/layout/market'
import Items from './components/Items/index'
import Logo from './components/Logo/index'
import SvgIcon from 'src/components/SvgIcon'
import Tabs from './components/Tabs/index'
import { ref } from 'vue'

import { useMarketPlace } from '.'
const { okxList, throttleListScroll } = useMarketPlace()

const activeTab = ref(1)

function handleRefresh() {}
</script>
<template>
  <Market>
    <div class="flex h-full">
      <div
        class="w-320px flex flex-col flex-shrink-0 relative overflow-hidden border-0 border-r border-solid border-#404753"
      ></div>
      <div class="bg-right w-full border-0 border-solid border-#6b7b87/60">
        <Tabs></Tabs>
        <div class="flex-grow px-46px py-20px">
          <div
            class="fz-5 relative flex justify-between flex-items-center text-#444950"
          >
            <div class="flex">
              <div
                @click="activeTab = 1"
                :class="{ 'active-tab text-#8DA3B3': activeTab === 1 }"
                class="mouse_pointer flex-center w-180px h-40.5px"
              >
                ITEMS
              </div>
              <div
                @click="activeTab = 2"
                :class="{ 'active-tab text-#8DA3B3': activeTab === 2 }"
                class="mouse_pointer flex-center w-180px h-40.5px"
              >
                ACTIVITY
              </div>
            </div>
            <div class="flex">
              <Logo></Logo>
              <div class="flex flex-items-center ml-28px text-#fff">
                <div class="fz-5">{{ 313 }}</div>
                <div
                  @click="handleRefresh"
                  class="mouse_pointer transition-all hover:bg-#1f1f21 hover:text-#cdd8e0 w-32px h-32px ml-2.5 bg-#2e2e2e flex-center border border-solid border-#4a4f51 rounded-4px"
                >
                  <SvgIcon
                    name="PFP-FRESH"
                    width="20px"
                    class="pfp-refresh"
                  ></SvgIcon>
                </div>
              </div>
              <div></div>
            </div>
            <div class="line absolute left-0 bottom-0 w-full h-1px"></div>
          </div>
          <div class="pt-26px w-full overflow-y-auto">
            <Items
              @scroll="throttleListScroll"
              v-show="okxList.length"
              class="bg-#161F25 h-44vw overflow-y-auto"
              :list="okxList"
            ></Items>
          </div>
        </div>
      </div>
    </div>
  </Market>
</template>

<style lang="scss" scoped>
.bg-right {
  background: linear-gradient(180deg, #141b1f 0%, #1b2830 100%);
  border-radius: 5px;
}

.active-tab {
  @include bg100('src/asset/market/tab_active.webp');

  transition: background ease-in-out 0.3s;
}

.line {
  background: linear-gradient(
    90deg,
    #151b1f 0%,
    #77a3bd 10.03%,
    #6a93a6 41.92%,
    #141b1f 62.79%,
    #141b1f 100%
  );
  opacity: 0.4;
}
</style>
