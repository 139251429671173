<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useSoulCardMintStore } from 'src/stores/soulCardMint'
import TipsWl from './TipsWl'
import { useMediaStore } from 'src/stores/media'
import Icon_ing from 'src/asset/PFP/mint/Icon_ing.webp'
import Icon_not_start from 'src/asset/PFP/mint/Icon_not_start.webp'
import Icon_end from 'src/asset/PFP/mint/Icon_end.webp'
const props = defineProps({
  phase: Number,
})
const { mintInfo, phaseComputed } = storeToRefs(useSoulCardMintStore())
const { min2XL } = storeToRefs(useMediaStore())

const statusComputed = computed(() => {
  const phase = props.phase
  const currentPhase = phaseComputed.value
  if (phase > currentPhase) {
    return {
      class: 'not_start',
      title: 'NOT STARTED',
      img: Icon_not_start,
    }
  } else if (phase === currentPhase) {
    return {
      class: 'ing',
      title: 'ONGOING',
      img: Icon_ing,
    }
  } else {
    return {
      class: 'end',
      title: 'ENDED',
      img: Icon_end,
    }
  }
})
const titleComputed = computed(() =>
  props.phase === 1 ? 'FREE MINT ROUND' : 'PUBLIC ROUND',
)
const wlComputed = computed(() => (props.phase === 1 ? 10 : 20))
</script>
<template>
  <div
    class="flex-sb box text-color w-full border-0 border-b border-solid border-[var(--header-border-color)] mb-2"
    :class="`${statusComputed.class} ${min2XL ? 'box_2xl fz-4' : 'box_md fz-3'}`"
  >
    <div class="flex items-center">
      <span class="2xl:mr-2 md:mr-1.5 title-color">{{ titleComputed }}</span>
      <TipsWl :phase="phase">
        <div
          class="wl flex justify-center leading-none text-white mouse_pointer"
          :class="`${wlComputed ? 'green' : 'red'}_box`"
        >
          {{ wlComputed }}
        </div>
      </TipsWl>
    </div>
    <div class="flex items-center">
      <img :src="statusComputed.img" class="lock 2xl:mr-2 md:mr-1.5" />
      <span class="lock-text-color">{{ statusComputed.title }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import './themen';

.wl {
  &.green_box {
    @include bg100('src/asset/PFP/mint/Spots_green_BG.webp');
  }

  &.red_box {
    @include bg100('src/asset/PFP/mint/Spots_red_BG.webp');
  }
}

.box_2xl {
  .lock {
    @apply w-22px h-22px;
  }

  .wl {
    @apply w-52px h-22px;
  }
}

.box_md {
  .lock {
    @apply w-19px h-19px;
  }

  .wl {
    @apply w-44px h-19px;
  }
}
</style>
