<script setup>
import Icon_SoulCard from 'src/asset/soulCardMint/Icon_Soulcards.webp'
import Icon_SoulCard_Free from 'src/asset/soulCardMint/Icon_Soulcards_Free.webp'
import { storeToRefs } from 'pinia'
import { useMediaStore } from 'src/stores/media'
const { min2XL } = storeToRefs(useMediaStore())

const props = defineProps({
  phase: Number,
})
</script>
<template>
  <div class="text-white">
    <img
      :src="phase === 1 ? Icon_SoulCard_Free : Icon_SoulCard"
      class="2xl:w-22px 2xl:h-22px w-20px h-20px"
      alt=""
    />
    <div class="text-#fff family_blk inline-block">
      <template v-if="phase === 1">
        <span class="fz-6 ml-2 text-#3EB13C">FREE</span>
      </template>
      <template v-else>
        <span
          class="fz-6 mx-2 inline-block leading-none!"
          :class="`${min2XL ? 'fz-6' : 'fz-5'}`"
          >10</span
        >
        <span class="fz-4 leading-none!">USDC per NFT</span>
      </template>
    </div>
  </div>
</template>
