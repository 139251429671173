import { useAsyncState } from '@vueuse/core'
import { fetchNFTInfo, fetchPFPDetail1 } from 'src/api'
import { computed, ref, watch } from 'vue'
export function useNFTTable(props) {
  const listNFTRef = ref([])
  const listNFTComputed = computed(() => {
    return listNFTRef.value.map((item, index) => {
      return {
        ...item,
        ...props.list[index],
      }
    })
  })
  const { execute: fetchNFTList } = useAsyncState(
    async () => {
      const pList = props.list.map(item => {
        if (item.type) {
          return fetchNFTInfo(item.token)
          // return fetchNFTInfo(item.token || 1)
        } else {
          return fetchPFPDetail1(item.token)
          // return fetchPFPDetail(item.token || 1)
        }
      })
      listNFTRef.value = await Promise.all(pList)
    },
    null,
    {
      immediate: false,
    },
  )
  watch(
    () => props.list,
    () => {
      fetchNFTList()
    },
    {
      immediate: true,
    },
  )
  return {
    listNFTComputed,
  }
}
